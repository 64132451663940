<template>
    <div class="videoPage">
        <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
            :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
            <videoListsec :item="item" v-for="(item, index) in videoList" :key="index" />
        </Pullrefresh>
    </div>
</template>

<script>
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    import videoListsec from '@/views/widget/videoListsec.vue';
    import { queryLikeList } from '@/api/mine/myLike.js';
    export default {
        components: {
            Pullrefresh,
            videoListsec,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                pageNum: 1,
                pageSize: 10,
                videoList: []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.videoList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    bType: 1,
                    mType: 2,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    userId: 0,
                }
                let ret = await this.$Api(queryLikeList, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.mediaInfos || [];
                    this.videoList = this.videoList.concat(list);

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length <= 0) {
                        this.finished = true;
                    }
                    // if (list.length < this.pageSize) {
                    //     this.finished = true;
                    // }
                }
            },
        }

    }
</script>

<style lang='scss' scoped>
    .videoPage {
        // margin-top: 10px;
        padding: 10px 10px 0;
        height: calc(100vh - 44px);
        .video-cover {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
</style>