import axios from "@/utils/request";
// 查询喜欢列表
export function queryLikeList(data) {
    return axios.post(`/like/list`, data);
}

// AV解说列表
export function queryAvComment(data) {
    return axios.post(`/collect/list`, data);
}

// 楼凤列表
export function queryLfList(data) {
    return axios.post(`/loufeng/like/list`, data);
}
