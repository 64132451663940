<template>
    <div class="myLike">
        <div @click="$router.go(-1)" class="leftArrow">
            <svg-icon iconClass="leftArrow"></svg-icon>
        </div>
        <van-tabs swipeable animated v-model="activeIndex" @change="changeTab">
            <van-tab title="视频" key="0">
                <div class="tab_content">
                    <VideoList />
                </div>
            </van-tab>
            <van-tab title="短视频" key="1">
                <div class="tab_content">
                    <ShortVideoList />
                </div>
            </van-tab>
            <van-tab title="楼凤" key="2">
                <div class="tab_content">
                    <Loufeng />
                </div>
            </van-tab>
            <van-tab title="色区" key="3">
                <div class="tab_content">
                    <Community @showComment="showComment" @showMembership="showMembership" @showShare="showShare"
                        @showImg="showImg" ref="community" />
                </div>
            </van-tab>
            <van-tab title="AV解说" key="4">
                <div class="tab_content">
                    <AvComment />
                </div>
            </van-tab>

        </van-tabs>
        <membership v-show="membershipShow" :topicInfo="memberInfo" :membershipShow="membershipShow"
            @membershipClose="membershipShow=false" />
        <comment v-show="commentShow" :topicInfo="memberInfo" @commentSuccess="commentSuccess"
            :commentShow="commentShow" @commentClose="commentShow=false" />
        <share v-if="shareShow" :topicInfo="memberInfo" @shareSuccess="shareSuccess" :shareShow="shareShow"
            @shareClose="shareShow=false" />
        <transition name="shareShow">
            <div class="sw flex" v-if="bigImgShow">
                <div class="back" @click="bigImgShow=false">
                    <span class="back-icon"></span>
                </div>
                <swiper class="swiper-all" :options="swiperOption" ref="mySwiper">
                    <swiper-slide v-for="(item,index) in picList" :key="index">
                        <ImgDecypt class="ad-img" :src="item" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination"></div>
            </div>
        </transition>
    </div>
</template>

<script>
    import share from "@/views/widget/share";
    import comment from "@/views/widget/comment";
    import membership from "@/views/widget/membership";
    import VideoList from '@/views/mine/myLike/VideoList';
    import ShortVideoList from '@/views/mine/myLike/ShortVideoList';
    import Loufeng from '@/views/mine/myLike/Loufeng';
    import Community from '@/views/mine/myLike/Community';
    import AvComment from '@/views/mine/myLike/AvComment';
    import "swiper/dist/css/swiper.css";
    import {
        swiper,
        swiperSlide
    } from "vue-awesome-swiper";
    export default {
        components: {
            Loufeng,
            Community,
            AvComment,
            VideoList,
            ShortVideoList,
            comment,
            membership,
            share,
            swiper,
            swiperSlide,
        },
        name: 'myLike',
        data() {
            return {
                keyIndex: 0,
                activeIndex: 0, // 选中Tab下标
                commentShow: false, //评论是否显示
                membershipShow: false, //是否显示开通会员弹窗
                shareShow: false, //分享组件的显示与否
                memberInfo: {}, //开通会员弹窗需要的信息
                bigImgShow: false, //大图的显示与否
                picList: [], // 图片列表
                swiperOption: {
                    observeParents: true,
                    observe: true,
                    //显示分页
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true, //允许点击分页跳转
                        type: "fraction",
                    },
                },
            }
        },
        created() {
            if (this.$route.query.i) {
                this.activeIndex = Number(this.$route.query.i);
            } else {
                this.activeIndex = 0;
            }
        },
        methods: {
            // 显示评论
            showComment(data) {
                this.memberInfo = data.item;
                this.commentShow = true;
            },
            // 显示分享
            showShare(data) {
                this.memberInfo = data.item;
                this.shareShow = true;
            },
            commentSuccess() {
                //评论成功评论条数加一

                // this.mediaInfos[this.keyIndex].commentNum++;
                this.$refs.community.communityList[this.keyIndex].commentNum++;
            },
            shareSuccess() {
                //分享成功回调
                // this.mediaInfos[this.keyIndex].shareNum++;
                this.$refs.community.communityList[this.keyIndex].shareNum++;
            },
            // 显示图片
            showImg(list) {
                this.bigImgShow = true;
                this.picList = list;
            },
            // 显示提示
            showMembership(data) {
                this.memberInfo = data;
                this.membershipShow = true;
            },
            changeTab(index) {
                this.$router.replace({path: '/myLike', query: {i: index}})
            }
        }
    }
</script>

<style lang='scss' scoped>
    .myLike {
        // position: relative;
        // height: 100%;

        .leftArrow {
            position: absolute;
            left: 16px;
            top: 22px;
            transform: translate(0, -50%);
            font-size: 15px;
            color: $white1;
            z-index: 2;
        }

        /deep/ .van-tabs__wrap {
            background: $vermillion;
        }

        /deep/ .van-tabs__nav {
            width: 340px;
            margin-left: 30px;
            height: 44px;
            background: $vermillion;
        }

        /deep/ .van-tab__text {
            font-size: 15px;
            color: $white1;
        }

        /deep/ .van-tabs__line {
            background: $white1;
            bottom: 20px;
            width: 40px;
            // width: 50%;
        }

        /deep/ .van-tab--active {
            .van-tab__text {
                font-weight: 600;
                font-size: 18px;
            }

        }

        .tab_content {
            // padding: 0 16px;
            // overflow: auto;
            // -ms-overflow-style: none;
            // height: calc(100vh - 44px);
            // background: $whiteThree;
        }

        .sw {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #000;
            z-index: 14;
            justify-content: center;
            align-items: center;
        }

        .swiper-all {
            width: 100%;
            height: 100%;
        }

        .swiper-all /deep/ .van-image__img {
            object-fit: contain !important;
        }

        .swiper-all /deep/ .van-image {
            background: #000;
        }

        .back {
            position: absolute;
            top: 0;
            left: 0;
            width: 52px;
            height: 52px;
            text-align: center;
            line-height: 62px;
            z-index: 11;
        }

        .back-icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url("../../../assets/png/back_icon.png") center center no-repeat;
            background-size: 100%;
        }

        .swiper-pagination {
            color: #ffffff;
        }
    }
</style>