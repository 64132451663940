<template>
    <div class="myLike_loufeng">
        <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
            :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
            <van-grid column-num="2" :border="false" class="gridBox">
                <van-grid-item use-slot v-for="(item, index) in lfList" :key="index">
                    <div class="lfBox" @click="jumpLoufeng(item)">
                        <ImgDecypt :src="item.img" class="imageUrl" />
                        <div class="title">{{item.title}}</div>
                        <div class="info">{{`${item.height}cm.${item.age}.${item.cup}`}}</div>
                        <div class="price">价格：{{item.srvPrice}}</div>
                        <div class="label" v-if="item.payable">可赔付</div>
                        <!-- <div class="playerBtn">
                            <img class="playerIcon" src="@/assets/png/play_icon.png">
                        </div> -->
                    </div>
                </van-grid-item>
            </van-grid>
        </Pullrefresh>
    </div>
</template>

<script>
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    import {
        queryLfList
    } from "@/api/mine/myLike.js";
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                pageNum: 1,
                pageSize: 10,
                lfList: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.lfList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    bType: 2,
                    mType: 1,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    userId: 0,
                }
                let ret = await this.$Api(queryLfList, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.list;
                    this.lfList = this.lfList.concat(list);

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
            // 跳转
            jumpLoufeng(item) {
                this.$router.push({
                    name: 'loufengDetail',
                    params: {
                        id: item.id
                    }
                })
            }
        }
    }
</script>

<style lang='scss' scoped>
    .myLike_loufeng {
        padding: 10px 10px;
        height: calc(100vh - 44px);

        // overflow-y: auto;
        // -ms-overflow-style: none;
        // height: 100%;
        // margin: 0 21px;
        // margin-bottom: 100px;
        // .gridBox {
        //     // margin-top: 20px;
        //     // margin-bottom: 100px;
        // }

        .lfBox {
            position: relative;
            overflow: hidden;
            width: 167px;
            // height: 318px;
            border-radius: 6px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);

            .imageUrl {
                width: 100%;
                height: 234px;
            }

            .title {
                font-size: 13px;
                color: $blackThree;
                padding: 5px 4px 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .info {
                font-size: 10px;
                color: $purpleyGrey;
                padding: 0 4px;
                margin-bottom: 3px;
            }

            .price {
                font-size: 10px;
                color: $vermillionTwo;
                padding: 0 4px;
                margin-bottom: 9px;
            }

            .label {
                position: absolute;
                top: 0;
                left: 0;
                padding: 0 8px;
                height: 24px;
                line-height: 24px;
                font-size: 13px;
                border-radius: 6px 0 6px 0;
                color: $white1;
                background: $vermillionTwo;
            }

            .playerBtn {
                position: absolute;
                display: flex;
                // justify-content: center;    
                align-items: center;
                top: 6px;
                right: 7px;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.16);

                img {
                    margin: 0 5px 0 9px;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        /deep/ .van-grid-item__content {
            padding: 0 0 5px;
            // padding: 0;
        }
    }
</style>